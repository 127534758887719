<template>
  <div class="xyprewmain">
    <div style="height:40px;line-height:40px;font-size: 14px;text-align: left;margin-bottom: 20px;">
      <span style="font-weight:bold;color:#409EFF;margin-right:20px;font-size: 16px;"
        >今日寻医表统计</span
      >
      新增寻医表：{{ tjnum.xybzs }}；已审核寻医表：{{ tjnum.xyysh }}；已抓取寻医表：{{
        tjnum.xyyzq
      }}；
    </div>
    <div style="margin-bottom: 20px;text-align:left;">
      <!-- v-if="MyZDType == '5'"  -->
      <el-select
        v-model="MyZDState"
        placeholder="请选择状态"
        style="margin-right:10px;width:200px;"
      >
        <el-option
          v-for="(item, index) in MyXYStateoptions"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="MyZDDate"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="margin-right:10px;width:300px;"
      >
      </el-date-picker>
      <el-input
        placeholder="请输入电话号或用户编号"
        style="width:200px;"
        v-model="tjcustid"
        clearable
      ></el-input>
      <el-button style="margin-left: 20px" type="primary" @click="SearchMyDayZD">查询</el-button>
    </div>
    <el-table :data="MyDayList" border style="width: 100%">
      <el-table-column label="时间" prop="Creat_DT"></el-table-column>
      <el-table-column label="编号" prop="KID"></el-table-column>
      <el-table-column label="名称" prop="Dis_Name"></el-table-column>
      <el-table-column label="用户ID" prop="Cust_ID"></el-table-column>
      <el-table-column label="指定医生" prop="Z_Doc_Name"></el-table-column>
      <el-table-column prop="State" label="">
        <template slot-scope="scope">
          <!-- <span v-if="scope.row.State == 0">未提交</span>
          <span v-if="scope.row.State == 1">待挂号</span>
          <span v-if="scope.row.State == 2">已抓取</span>
          <span v-if="scope.row.State == 6">审核</span>
          <span v-if="scope.row.State == 9">已删除</span> -->
          <span
            class="TJtalkBtn"
            @click="
              EditXYMsg.noedit = false;
              EditXYMsg.cust_id = scope.row.Cust_ID;
              EditXYMsg.kid = scope.row.KID;
              EditXY = true;
            "
            >查看</span
          >
          <span
            class="TJtalkBtn"
            v-if="scope.row.State == 0 || scope.row.State == 1"
            @click="NewXYEdit(scope.row, '')"
            >编辑</span
          >
        </template>
      </el-table-column>
    </el-table>
    <p class="paging" style="text-align: center">
      <el-pagination
        @size-change="MyZDhandleSizeChange"
        @current-change="MyZDhandleCurrentChange"
        :current-page="MyZDcurrent"
        :page-sizes="MyZDpageSizes"
        :page-size="MyZDPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="MyZDtotalCount"
        :hide-on-single-page="MyZDvalue"
      ></el-pagination>
    </p>
    <!-- 编辑寻医弹窗开始 -->
    <el-dialog title="编辑寻医单" :modal="false" :visible.sync="EditXY" width="566px">
      <xypreview
        :kid="EditXYMsg.kid"
        :custid="EditXYMsg.custid"
        v-if="EditXY"
        :EditZD="EditXY"
        :noedit="EditXYMsg.noedit"
      ></xypreview>
    </el-dialog>
    <!-- 编辑寻医弹窗结束 -->
  </div>
</template>
<script>
import xypreview from "../components/xypreview.vue";
export default {
  components: {
    xypreview,
  },
  data() {
    return {
      EditXYMsg: {},
      EditXY: false,
      MyZDState: "", //我的诊单状态
      MyXYStateoptions: [
        //寻医表状态选项
        { value: "0", label: "未提交" },
        { value: "1", label: "待挂号" },
        { value: "6", label: "审核" },
        { value: "2", label: "已抓取" },
        { value: "9", label: "删除" },
      ],
      MyZDDate: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      MyDayList: [], //我的诊单列表
      MyZDcurrent: 1, //默认显示第几页
      MyZDtotalCount: 0, // 总条数，根据接口获取数据长度
      MyZDpageSizes: [20], // 个数选择器（可修改）
      MyZDPageSize: 20, // 默认每页显示的条数（可修改）
      MyZDvalue: false,
      tjcustid: "",
      tjnum: {
        xybzs: "0",
        xyysh: "0",
        xyyzq: "0",
      },
    };
  },
  mounted() {
    this.getnum();
  },
  methods: {
    getnum() {
      let date1 = this.getCurrentTime().split(" ")[0];
      let date3 = Number(date1.split("-")[2]) + 1;
      let date2 = date1.substring(8, 10);
      date2 = date1.replace(date2, date3);
      console.log(date1);
      console.log(date2);
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?" +
            "&datetime1=" +
            date1 +
            "&datetime2=" +
            date2 +
            "&state=1"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.tjnum.xybzs = res.data.count;
          } else {
          }
        })
        .catch((res) => {});
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?" +
            "&commit_datetime1=" +
            date1 +
            "&commit_datetime2=" +
            date2 +
            "&state=2"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.tjnum.xyyzq = res.data.count;
          } else {
          }
        })
        .catch((res) => {});
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?" +
            "&commit_datetime1=" +
            date1 +
            "&commit_datetime2=" +
            date2 +
            "&state=6"
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.tjnum.xyysh = res.data.count;
          } else {
          }
        })
        .catch((res) => {});
    },
    MyZDhandleSizeChange() {},
    MyZDhandleCurrentChange(currentPage) {
      let date1, date2;
      if (this.MyZDDate != "") {
        date1 = this.MyZDDate[0];
        date2 = this.MyZDDate[1];
      } else {
        date1 = "";
        date2 = "";
      }
      //寻医
      this.getMyDayDataXY(date1, date2, this.MyZDState, currentPage);
    },
    //编辑寻医
    NewXYEdit(row, type) {
      this.EditXYMsg.kid = row.KID;
      this.EditXYMsg.custid = row.Cust_ID;
      if (type == "look") {
        this.EditXYMsg.noedit = false;
      } else {
        this.EditXYMsg.noedit = true;
      }
      this.EditXY = true;
    },
    SearchMyDayZD() {
      if (this.MyZDDate == "" && this.MyZDState == "" && this.tjcustid == "") {
        this.$message({
          message: "请选择筛选条件",
          type: "error",
        });
        return;
      }
      let date1, date2;
      if (this.MyZDDate != "") {
        date1 = this.MyZDDate[0];
        date2 = this.MyZDDate[1];
      } else {
        date1 = "";
        date2 = "";
      }
      this.MyZDtotalCount = 0;
      this.MyDayList = [];
      //寻医
      this.getMyDayDataXY(date1, date2, this.MyZDState, 1);
    },
    //获取我的当天发送的寻医数据
    getMyDayDataXY(date1, date2, state, page) {
      //先判断数据格式，是手机号还是custid
      if (this.tjcustid.match(/^\d/)) {
        this.axios
          .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.tjcustid, doc_id: "" }))
          .then((res) => {
            if (res.data.code == 0) {
              console.log(res);
              var id = res.data.result[0].Cust_ID;
              this.axios
                .get(
                  "/field/get_z_newseadoc_mains?" +
                    "&datetime1=" +
                    date1 +
                    "&datetime2=" +
                    date2 +
                    "&state=" +
                    state +
                    "&page=" +
                    page +
                    "&size=" +
                    this.MyZDPageSize +
                    "&cust_id=" +
                    id
                )
                .then((res) => {
                  if (res.data.code == 0) {
                    this.MyZDtotalCount = res.data.count;
                    this.MyDayList = res.data.result;
                  } else {
                    this.$message(res.data.msg);
                  }
                })
                .catch((res) => {});
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        this.axios
          .get(
            "/field/get_z_newseadoc_mains?" +
              "&datetime1=" +
              date1 +
              "&datetime2=" +
              date2 +
              "&state=" +
              state +
              "&page=" +
              page +
              "&size=" +
              this.MyZDPageSize +
              "&cust_id=" +
              this.tjcustid
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.MyZDtotalCount = res.data.count;
              this.MyDayList = res.data.result;
            } else {
              this.$message(res.data.msg);
            }
          })
          .catch((res) => {});
      }
    },
    /** 获取当前时间 格式：yyyy-MM-dd HH:MM:SS*/
    getCurrentTime() {
      var date = new Date(); //当前时间
      var month = this.zeroFill(date.getMonth() + 1); //月
      var day = this.zeroFill(date.getDate()); //日
      var hour = this.zeroFill(date.getHours()); //时
      var minute = this.zeroFill(date.getMinutes()); //分
      var second = this.zeroFill(date.getSeconds()); //秒
      //当前时间
      var curTime =
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      return curTime;
    },
    /**补零*/
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    },
  },
};
</script>
<style>
.xyprewmain {
  padding: 20px;
}
.TJtalkBtn {
  margin-left: 20px;
  cursor: pointer;
  color: green;
}
</style>
